.sel_container{
    display: block;
}
.sel_input{
    width: 100%;
    height: 35px;

    border: 2px solid #dfe1e6;
    border-radius: 3px;
    box-sizing: border-box;
    font-size: inherit;
    margin: 0;
    transition: background-color .2s ease-in-out,border-color .2s ease-in-out;
    vertical-align: initial;

    line-height: 1.42857143;
    padding: 3px 4px;
}
.sel_input > option{
    background-color: white;
}
.sel_input:focus{
    outline: none;
}
.sel_input:disabled{
    background-color: #f2f2f2;
}
.sel_information_text{
    font-weight: bold;
    margin: 0;
    padding: 3px;
}
.sel_white{
    color: white;
}
.sel_black{
    color: black;
}
.sel_error{
    border-color: crimson !important;
}