.gl_containter{
  justify-content: center;
  align-items: center;
  display: flex;
  flex-direction: column;
  width: inherit;
}
.gl_grid{
  display: grid;
}
.gl_game_item{
  border: 1px solid lightgray;
  margin: 10px;
  border-radius: 8px;
}
.gl_game_item:hover{
  cursor: pointer;
}
.gl_logo{
  padding: 13%;
  padding-bottom: 5px;
}
.gl_game_title{
  display: flex;
  justify-content: center;
  align-items: center;
  height: 40px;
  font-weight: bold;
}
.gl_game_description_content{
  padding: 10px;
  background-color: #f0f0f0;
}
.gl_game_description{
  height: 110px;
  overflow: hidden;
  color: #525252;
  padding: 5px;
  font-size: 14px;
}
.gl_game_play_btn{
  display: flex;
  align-items: center;
  justify-content: center;
  flex-wrap: nowrap;
  border-radius: 5px;
  min-height: 42px;
  color: #fff;
  border: 1px solid lightgray;
  font-weight: bold;
  background-color: #4d88f9;
}
.gl_intro{
  width: 100%;
  height: 175px;
  justify-content: center;
  align-items: center;
  display: flex;
  font-size: 42px;
  background-color: #F5F6F6;
}
.gl_intro_icon{
  width: 100px;
  height: 100px;
  margin-left: 20px;
  border: 1px solid black;
  border-radius: 5px;
}
.gl_intro_icon:hover{
  cursor: pointer;
  opacity: 0.6;
}

@media (max-width:576px){
  .gl_grid{ width: 95%;grid-template-columns: 1fr 1fr; }
  .gl_game_description{ font-size: 11px; }
  .gl_intro{font-size: 12px; height: 100px;}
  .gl_intro_icon{
    width: 50px;
    height: 50px;
    margin-left: 10px;
  }
  .footer { position: relative; padding: 10px; }
}
@media (max-width:768px) and (min-width:576px){
  .gl_grid{ width: 550px; grid-template-columns: 1fr 1fr; }
  .footer { position: absolute; bottom: 10px; }
}
@media (max-width:992px) and (min-width:768px){
  .gl_grid{ width: 720px; grid-template-columns: 1fr 1fr 1fr; }
  .footer { position: absolute; bottom: 10px; }
}
@media (max-width:1200px) and (min-width:992px){
  .gl_grid{ width: 950px; grid-template-columns: 1fr 1fr 1fr 1fr; }
  .footer { position: absolute; bottom: 10px; }
}
@media (min-width:1200px){
  .gl_grid{ width: 1200px; grid-template-columns: 1fr 1fr 1fr 1fr 1fr; }
  .footer { position: absolute; bottom: 10px; }
}

@media screen and (orientation:landscape) and (max-width:992px) {
  .gl_grid{ width: 95%;grid-template-columns: 1fr 1fr; }
  .gl_game_description{ font-size: 13px; }
  .gl_intro{font-size: 14px; height: 150px;}
  .footer { position: relative; padding: 10px; }
}
.adsbygoogle{

}