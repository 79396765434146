.header_container{
  width: 100%;
  display: flex;
  flex-direction: row;
  border-bottom: 1px #d4d4d4 solid;
  background-color: #fff;
  height: 60px;
  position: fixed;
}
.app_logo{
  width: 50px;
  height: 50px;
  margin: auto 5px;
}
.app_logo:hover{
  cursor: pointer;
}
.header_logo{
  width: 50px;
  height: 50px;
  margin: auto 5px auto 20px;
}
.header_logo:hover{
  cursor: pointer;
}
.header_icon{
  width: 40px;
  height: 40px;
  margin: auto 10px;
}
.header_icon:hover{
  cursor: pointer;
}
.header_empty_middle{
  margin: auto;
}
.header_game_logo_div{
   height: 100%;
}
/* 
@media (max-width:576px){
  .header_logo{
    display: none;
  }
  .settings_icon{
    display: none;
  }
} */