.nf_404{
    font-size: 100pt;
    color: coral;
    text-align: center;
}
.nf_container{
    color: #1D3557;
    font-weight: bold;
    padding-bottom: 4px;
    padding-right:15px;
    padding-left:15px;
    margin:auto;
}
@media (min-width:576px){.nf_container{width:540px}}
@media (min-width:768px){.nf_container{width:720px}}
@media (min-width:992px){.nf_container{width:960px}}
@media (min-width:1200px){.nf_container{width:1140px}}