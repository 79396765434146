.kural_container {
    justify-content: center;
    align-items: center;
    display: flex;
    margin: 0 auto;
    height: 100%; 
}
.kural_game_container{
    width: 100%;
    justify-content: center;
    align-items: center;
    display: flex;
    flex-direction: column;
}
.kural_find_container {
    justify-content: center;
    align-items: center;
    display: flex;
    width: 100%;
}

.kural_given_wordlist {
    display: grid;
    grid-template-columns: 1fr 1fr 1fr 1fr;
    flex: 1;
    width: 100%;
    margin-top: 100px;
}

.kural_given_word {
    display: flex;
    justify-content: center;
    align-items: center;
}

.kural_given_word_btn {
    display: flex;
    justify-content: center;
    align-items: center;
    border-bottom: 1px solid lightgray;
    cursor: pointer;
    background-color: rgba(0, 0, 0, 0.2);
    flex: 1;
}

.kural_find_wordlist {
    display: grid;
    grid-template-columns: 1fr 1fr 1fr 1fr;
    flex: 1;
    width: 100%;
}

.kural_find_word {
    display: flex;
    justify-content: center;
    align-items: center;
}
.droptarget, .ddcontainersource, .ddcontainer {
    width: 100%;
}
.kural_find_word_btn {
    display: flex;
    justify-content: center;
    align-items: center;
    border-bottom: 1px solid lightgray;
    cursor: pointer;
    background-color: rgba(0, 0, 0, 0.2);
    flex: 1;
    margin: 1px;
    height: 40px;
}

.kural_find_word_allow {
    border: 1px dashed black;
    margin: 0;
}

.kural_check_btn {
    display: flex;
    justify-content: center;
    align-items: center;
    padding: 10px;
    border-bottom: 1px solid lightgray;
    cursor: pointer;
    background-color: rgba(0, 0, 0, 0.2);
}

.kural_check_btn:hover {
    opacity: 0.8;
}

.btn_disable {
    opacity: 0.4 !important;
}

.kural_find_word_pass {
    background-color: green;
}

.kural_find_word_fail {
    background-color: red;
}
.howtoplay_intro{
    margin: 20px 0;
}
.trail_count{
    display: flex;
    justify-content: center;
    align-items: center;
    font-size: 30px;
}
@media screen and (orientation:landscape) and (max-width:992px) {
    .kural_given_wordlist { margin-top: 10px;}
    .font_size{ font-size: 12px; }
    .kural_find_word_btn{ height: 25px;}
    .kural_given_word_btn{ padding: 3px !important; }
    .kural_find_word{ padding: 3px !important; }
}

@media (max-width:576px) {
    .kural_container{ width: 95%; }
    .kural_given_word_btn { padding: 5px; }
    .kural_given_word { padding: 5px; }
    .kural_find_word_btn { padding: 5px; }
    .kural_find_word { padding: 2px; }
    .font_size{ font-size: 12px; }
}

@media (max-width:768px) and (min-width:576px) {
    .kural_container{ width: 500px; }
    .kural_given_word_btn { padding: 10px; }
    .kural_given_word { padding: 10px; }
    .kural_find_word_btn { padding: 10px; }
    .kural_find_word { padding: 10px; }
    .font_size{ font-size: 14px; }
}

@media (max-width:992px) and (min-width:768px) {
    .kural_container{ width: 500px; }
    .kural_given_word_btn { padding: 10px; }
    .kural_given_word { padding: 10px; }
    .kural_find_word_btn { padding: 10px; }
    .kural_find_word { padding: 10px; }
    .font_size{ font-size: 14px; }
}

@media (max-width:1200px) and (min-width:992px) {
    .kural_container{ width: 500px; }
    .kural_given_word_btn { padding: 10px; }
    .kural_given_word { padding: 10px; }
    .kural_find_word_btn { padding: 10px; }
    .kural_find_word { padding: 10px; }
    .font_size{ font-size: 16px; }
}

@media (min-width:1200px) {
    .kural_container{ width: 500px; }
    .kural_given_word_btn { padding: 10px; }
    .kural_given_word { padding: 10px; }
    .kural_find_word_btn { padding: 10px; }
    .kural_find_word { padding: 10px; }
    .font_size{ font-size: 16px; }
}