.home_banner{
    min-width: 1200px;
    margin: 100px auto;
}
.home_title{
    font-size: 72px;
    font-weight: 700;
    line-height: 1;
    letter-spacing: .01em;
    -webkit-font-smoothing: antialiased;
    font-feature-settings: 'calt' 0;
}
.home_subtitle{
    font-size: 24px;
    max-width: 400px;
    margin-top: 36px;
}
.home_signup{
    height: 40px;
    max-width: 150px;
    display: flex;
    justify-content: center;
    align-items: center;
    padding: 0 20px;
    margin-top: 35px;
    background-color: #2d7ff9;
    border-radius: 20px;
    color: white;
    font-size: 14px;
    cursor: pointer;
}
.home_selection{
    display: flex;
    justify-content: center;
    align-items: center;
    width: inherit;
    height: inherit;
}
@media (max-width:576px){.home_selection{flex-direction:column}}
@media (max-width:768px) and (min-width:576px){.home_selection{flex-direction:column}}
@media (max-width:992px) and (min-width:768px){.home_selection{flex-direction:row}}
@media (max-width:1200px) and (min-width:992px){.home_selection{flex-direction:row}}
@media (min-width:1200px){.home_selection{flex-direction:row}}

.home_select_button{
    font-size: 24px;
    min-width: 250px;
    margin: 30px;
}

.home_select_button {
  align-items: center;
  appearance: none;
  background-color: #fff;
  border-radius: 24px;
  border-style: none;
  box-shadow: rgba(0, 0, 0, .2) 0 3px 5px -1px,rgba(0, 0, 0, .14) 0 6px 10px 0,rgba(0, 0, 0, .12) 0 1px 18px 0;
  box-sizing: border-box;
  color: #3c4043;
  cursor: pointer;
  display: inline-flex;
  fill: currentcolor;
  font-family: "Google Sans",Roboto,Arial,sans-serif;
  font-size: 14px;
  font-weight: 500;
  height: 48px;
  justify-content: center;
  letter-spacing: .25px;
  line-height: normal;
  max-width: 100%;
  overflow: visible;
  padding: 2px 24px;
  position: relative;
  text-align: center;
  text-transform: none;
  transition: box-shadow 280ms cubic-bezier(.4, 0, .2, 1),opacity 15ms linear 30ms,transform 270ms cubic-bezier(0, 0, .2, 1) 0ms;
  user-select: none;
  -webkit-user-select: none;
  touch-action: manipulation;
  width: auto;
  will-change: transform,opacity;
  z-index: 0;
}

.home_select_button:hover {
  background: #F6F9FE;
  color: #174ea6;
}

.home_select_button:active {
  box-shadow: 0 4px 4px 0 rgb(60 64 67 / 30%), 0 8px 12px 6px rgb(60 64 67 / 15%);
  outline: none;
}

.home_select_button:focus {
  outline: none;
  border: 2px solid #4285f4;
}

.home_select_button:not(:disabled) {
  box-shadow: rgba(60, 64, 67, .3) 0 1px 3px 0, rgba(60, 64, 67, .15) 0 4px 8px 3px;
}

.home_select_button:not(:disabled):hover {
  box-shadow: rgba(60, 64, 67, .3) 0 2px 3px 0, rgba(60, 64, 67, .15) 0 6px 10px 4px;
}

.home_select_button:not(:disabled):focus {
  box-shadow: rgba(60, 64, 67, .3) 0 1px 3px 0, rgba(60, 64, 67, .15) 0 4px 8px 3px;
}

.home_select_button:not(:disabled):active {
  box-shadow: rgba(60, 64, 67, .3) 0 4px 4px 0, rgba(60, 64, 67, .15) 0 8px 12px 6px;
}

.home_select_button:disabled {
  box-shadow: rgba(60, 64, 67, .3) 0 1px 3px 0, rgba(60, 64, 67, .15) 0 4px 8px 3px;
}