.privacy_notice_container{
    position: fixed;
    top: 0;
    bottom: 0;
    right: 0;
    left: 0;
    display: flex;
    justify-content: center;
    align-items: center;
    background: rgba(0,0,0,0.5);
}
.privacy_notice_popup{
    background-color: white;
    padding: 10px;
    overflow: scroll;
    max-height: 90%;

}
.privacy_notice_text{
    font-size: 12px;
    line-height: 1.5;
    margin: 3px 0 8px 0;
}
.privacy_notice_action{
    display: flex;
    justify-content: center;
    align-items: center;
}
.privacy_notice_action_btn{
    background-color: #000000;
    border-color: #000000;
    color: #FFFFFF;
    padding: 12px 10px;
    font-size: 12px;
    font-weight: 600;
    line-height: 1.4;
    letter-spacing: .05em;
    height: auto;
    white-space: normal;
    word-break: break-word;
    word-wrap: break-word;
    border-radius: 2px;
    cursor: pointer;
    box-sizing: border-box;
    margin: 0 10px;
    text-align: center;
    min-width: 160px;
}
.privacy_notice_cutom_item{
    border-top: 1px solid lightgray;
    padding: 10px 0;
    display: flex;
}
.privacy_notice_custom_content{
    display: block;
    width: 100%;
}
.privacy_notice_custom_action{
    display: flex;
    align-items: center;
    padding: 0 10px;
}
.privacy_notice_custom_heading{
    font-size: 18px;
    font-weight: bold;
    margin: 5px 0 10px 0;
}
.privacy_notice_custom_sub_heading{
    font-size: 14px;
    margin: 5px 0 10px 0;
}
.privacy_notice_custom_action_always{
    text-align: right;
    color: blue;
    font-size: 14px;
    font-weight: bold;
}
.privacy_notice_close{
    margin-top: 18px;
    justify-content: flex-end;
    align-items: center;
    display: flex;
}
.privacy_notice_close_icon{
    width: 40px;
    height: 40px;
    justify-content: center;
    align-items: center;
    display: flex;
}
.privacy_notice_close_icon:hover{
    background-color: lightgray;
    border-radius: 20px;
    cursor: pointer;
}
@media (max-width:576px){.privacy_notice_popup{width:80%}.privacy_notice_action{display: block}.privacy_notice_action_btn{ margin-bottom: 5px;}}
@media (min-width:576px) and (max-width:768px){.privacy_notice_popup{width:550px}}
@media (min-width:768px) and (max-width:992px){.privacy_notice_popup{width:720px}}
@media (min-width:992px) and (max-width:1200px){.privacy_notice_popup{width:950px}}
@media (min-width:1200px){.privacy_notice_popup{width:1140px}}