:root {
  --blue: #1e90ff;
  --white: #ffffff;
  --primaryOrange: #ff7126;
}

.howtoplay_title{
  display: flex;
  justify-content: center;
  align-items: center;
  position: relative;
}
.howtoplay_bold{
  font-weight: bold;
}
.howtoplay_close{
  position: absolute;
  right: 0;
  width: 30px;
  height: 30px;
  display: flex;
  justify-content: center;
  align-items: center;
  border-radius: 15px;
}
.howtoplay_close:hover{
  cursor: pointer;
  background-color: lightgray;
}
.bold{
 font-weight: bold;
}

@media (max-width:576px){
  .howtoplay{ display: block; margin: 0 auto; max-width: 85%; }
}
@media (max-width:768px) and (min-width:576px){
  .howtoplay{ display: block; margin: 0 auto; max-width: 500px; }
}
@media (max-width:992px) and (min-width:768px){
  .howtoplay{ display: block; margin: 0 auto; max-width: 500px; }
}
@media (max-width:1200px) and (min-width:992px){
  .howtoplay{ display: block; margin: 0 auto; max-width: 500px; }
}
@media (min-width:1200px){
  .howtoplay{ display: block; margin: 0 auto; max-width: 500px; }
}