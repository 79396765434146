.switch-group{
  width: 40px;
  height: 20px;
  margin: 0 10px;
  position: relative;
}
.switch{
  position: absolute;
  top: 0;
  right: 0;
  display: inline-block;
  width: 40px;
  height: 20px;
  margin-bottom: 0.5rem;
}
.switch__slider:before{
  position: absolute;
  content: "";
  height: 12px;
  width: 12px;
  left: 4px;
  bottom: 4px;
  background-color: white;
  border-radius: 50%;
  -webkit-transition: .4s;
  transition: .4s;
}
.switch__slider{
  position: absolute;
  cursor: pointer;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: #ccc;
  border-radius: 10px;
  -webkit-transition: .4s;
  transition: .4s;
}

.switch input:checked + .switch__slider {
  background-color: #28A834;
}

.switch input:checked + .switch__slider:before {
  -webkit-transform: translateX(20px);
  -ms-transform: translateX(20px);
  transform: translateX(20px);
}