.support_title{
    font-size: 60pt;
    color: #7F3250;
    text-align: center;
}
.support_subtitle{
    font-size: 45pt;
    color: coral;
    text-align: center;
}
.support_message{
    font-size: 16pt;
    font-weight: normal;
    color: black;
    text-align: center;
}
.delete_action{
    display: flex;
    justify-content: center;
    cursor: pointer;
    background-color: red;
    padding: 20px;
}
.margin30{
    margin-top: 30px;
}
.support_email_link{
    font-size: 16pt;
    color: darkgreen;
    font-weight: normal;
    text-align: center;
    text-decoration: none;
}
.support_email_link:hover{
    cursor: pointer;
    text-decoration: underline;
}