.cw_show_wrong_clicks{
    height: 30px;
    width: 90px;
    display: flex;
    margin: 10px;
}
.cw_wrong_click{
    height: 30px;
    width: 30px;
    opacity: 1;
}
.cw_wrong_click_not{
    height: 30px;
    width: 30px;
    opacity: 0.2;
}


@media (max-width:576px){
}
@media (max-width:768px) and (min-width:576px){
}
@media (max-width:992px) and (min-width:768px){
}
@media (max-width:1200px) and (min-width:992px){
}
@media (min-width:1200px){
}