.game1_main_container{
  justify-content: center;
  align-items: center;
  display: flex;
  width: inherit;
}
.game1_main{
  display: grid;
  position: relative;
  z-index: 0;
}
.game1_image_section_row{
  display: grid;
  overflow: hidden;
}
.game1_image_section{
  overflow: hidden;
}
.game1_image{
  cursor: pointer;
  -ms-user-select: none;
  user-select: none;
}
.message_won{
  justify-content: center;
  align-items: center;
  display: flex;
  flex-direction: column;
  background-color: rgba(255,255,255,0.4);
}
.message_won_text{
  color: black;
  font-weight: bold;
}
.play_again_buton{

}
.leader_board{
  width: 100%;
  border: 1px solid lightgray;
}
.leader_board_row{
  display: flex;
  width: 100%;
  height: 40px;
}
.leader_name{
  display: flex;
  justify-content: flex-start;
  align-items: center;
  width: 70%;
  border: 1px solid lightgray;
  padding-left: 5px;
}
.leader_move{
  display: flex;
  justify-content: center;
  align-items: center;
  width: 15%;
  border: 1px solid lightgray;
}
.leader_time{
  display: flex;
  justify-content: center;
  align-items: center;
  width: 15%;
  border: 1px solid lightgray;
}

@media (max-width:576px){
  .game1_main{ width: 80%; height: auto; }
  .message_won_text{ font-size: 24px; }
}
@media (max-width:768px) and (min-width:576px){
  .game1_main{ width: 80%; height: 80%; }
  .message_won_text{ font-size: 24px; }
}
@media (max-width:992px) and (min-width:768px){
  .game1_main{ width: 1000px; height: 1000px; }
  .message_won_text{ font-size: 36px; }
}
@media (max-width:1200px) and (min-width:992px){
  .game1_main{ width: 1000px; height: 1000px; }
  .message_won_text{ font-size: 48px; }
}
@media (min-width:1200px){
  .game1_main{ width: 1000px; height: 1000px; }
  .message_won_text{ font-size: 48px; }
}