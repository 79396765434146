.ns_geme_main_container{
  justify-content: center;
  align-items: center;
  display: flex;
  flex-direction: column;
  width: inherit;
}
.ns_game_main{
  display: grid;
  position: relative;
  z-index: 0;
}
.ns_game_row{
  display: grid;
  overflow: hidden;
}
.ns_game_number_section{
  overflow: hidden;
	-webkit-perspective: 1000;
	width: 100%;
	cursor: pointer;
}
.ns_game_number_section:hover{
  cursor: pointer;
}
.message_over{
  justify-content: center;
  align-items: center;
  display: flex;
  flex-direction: column;
  background-color: rgba(255,255,255,0.4);
  min-height: 200px;
}
.message_over_text{
  font-size: 48px;
  color: white;
  font-weight: bold;
  text-shadow: 2px 2px 4px #000000;
}
.game_number_cell,.game_number_cellnone{
  margin: 5px;
  border: 1px #d4d4d4 solid;
  width: calc(100% - 10px);
  height: calc(100% - 10px);
  display: flex;
  justify-content: center;
  align-items: center;
  font-weight: 400;
}
.game_number_cellnone:hover{
  background-color: #f5f5f5 !important;
}
.correct{
  background-color: #ff7126 !important;
}
.wrong{
  background-color: #969696 !important;
}

 /* animatin stats here */
.flipper {
	transition: 0.8s;
	-webkit-transform-origin: 100% 50%;
	-webkit-transform-style: preserve-3d;
	position: relative;
	height: 100%;
}
.on {
	-webkit-transform: rotateX(-180deg);
}
.off {
	-webkit-transform: rotateX(0deg);
}
.front, .back {
	width: 100%;
	height: 100%;
	position: absolute;
	-webkit-backface-visibility: hidden;
}
.front {
}
.ns_game_number_section .back {
	-webkit-transform: rotateX(180deg);
}
 /* animatin ends here */

.ng_action_buttons{
  display: flex;
  justify-content: center;
  align-items: center;
}
.ng_action_button{
  font-size: 24px;
  min-width: 90%;
  margin: 10px;
}

.ng_action_button {
  align-items: center;
  appearance: none;
  background-color: #fff;
  border-radius: 24px;
  border-style: none;
  box-shadow: rgba(0, 0, 0, .2) 0 3px 5px -1px,rgba(0, 0, 0, .14) 0 6px 10px 0,rgba(0, 0, 0, .12) 0 1px 18px 0;
  color: #3c4043;
  cursor: pointer;
  display: flex;
  font-size: 14px;
  font-weight: 500;
  height: 48px;
  justify-content: center;
}

.ng_action_button:hover {
  background: #F6F9FE;
  color: #174ea6;
}

.ng_action_button:active {
  box-shadow: 0 4px 4px 0 rgb(60 64 67 / 30%), 0 8px 12px 6px rgb(60 64 67 / 15%);
  outline: none;
}

.toast_message{
  position: absolute;
  top: 20%;
  max-width: 200px;
  background: black;
  color: white;
  padding: 20px;
  z-index: 100;
  border-radius: 5px;
  text-align: center;
  margin-left: auto;
  margin-right: auto;
}
.lineover{
  display: flex;
  border-top: 1px #d4d4d4 solid;
}
.btn_skip{
  
}
.link_button{
  padding: 20px 0;
}
.link_button:hover{
  cursor: pointer;
  text-shadow: 3px 3px 5px #bfbfbf;
}
.result_rows{
  width: auto;
}
.result_rows_guess{
  width: 80%;
  margin-bottom: 24px;
}
.result_flex{
  display: flex;
  flex: 1;
}
.stat_column{
  display: block;
  margin: 0 10px;
}
.stat_count{
  font-size: 36px;
  font-weight: 400;
  display: flex;
  align-items: center;
  justify-content: center;
  text-align: center;
  letter-spacing: 0.05em;
  font-variant-numeric: proportional-nums;
}
.stat_name{
  font-size: 12px;
  display: flex;
  align-items: center;
  justify-content: center;
  text-align: center;
}
.guess_count{
  display: flex;
  width: 100%;
  padding-bottom: 4px;
}
.guess_count_number{
  margin: 0 5px;
  font-size: 14px;
  line-height: 20px;
  width: 15px;
}
.guess_progress_container{
  display: flex;
  width: 100%;
}
.guess_progress{
  display: flex;
  width: 100%;
  background-color: #787c7e;
}
.player_guess{
  justify-content: flex-end;
  padding-right: 8px;
  display: flex;
  width: 100%;
  font-size: 14px;
  line-height: 20px;
  color: white;
  font-weight: bold;
}

@media (max-width:576px){
  .ns_game_main{ width: 80%; height: auto; }
  .ng_action_button { width: auto; }
  .player_guess{
    padding-right: 3px;
  }
}
@media (max-width:768px) and (min-width:576px){
  .ns_game_main{ width: 80%; height: 80%; }
  .ng_action_button { width: auto; }
}
@media (max-width:992px) and (min-width:768px){
  .ns_game_main{ width: 500px; height: 500px; }
  .ng_action_button { width: auto; }
}
@media (max-width:1200px) and (min-width:992px){
  .ns_game_main{ width: 500px; height: 500px; }
  .ng_action_button { width: auto; }
}
@media (min-width:1200px){
  .ns_game_main{ width: 500px; height: 500px; }
  .ng_action_button { width: auto; }
}