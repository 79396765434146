.pop_popup_background{
  position: fixed;
  top: -11px;
  left: 0;
  bottom: 0;
  z-index: 10;
  width: 100%;
  float: left;
  background-color: rgba(255, 255, 255, 0.5);
}

.pop_popup{
  width: 100%;
  height: 100%;
  position: fixed;
}
.pop_popup_body{
  display: inline-block;
  z-index: 11;
  margin: auto;
  text-align: left;
  padding: 10px;
  background-color: #ffffff;
  border-radius: 8px;
  max-height: 90%;
  overflow: auto;
}
.pop_popup_title{
  display: inline-block;
  width: 100%
}
.pop_popup_close_icon{
  width: 30px;
  height: 30px;
  float: right;
  display: flex;
  justify-content: center;
  align-items: center;
}
.pop_popup_close_icon:hover{
  background-color: lightgray;
  border-radius: 15px;
  cursor: pointer;
}

@media (max-width:576px){
  .pop_popup_body{
    position: fixed;
    left: 50%;
    top: 50%;
    transform: translate(-50%, -50%);
    box-shadow: 0 4px 23px 0 rgb(0 0 0 / 20%);
    width: 80%;
  }
}
@media (max-width:768px) and (min-width:576px){
  .pop_popup_body{
    position: fixed;
    left: 50%;
    top: 50%;
    transform: translate(-50%, -50%);
    box-shadow: 0 4px 23px 0 rgb(0 0 0 / 20%);
    width: 80%;
  }
}
@media (max-width:992px) and (min-width:768px){
  .pop_popup_body{
    position: fixed;
    left: 50%;
    top: 50%;
    transform: translate(-50%, -50%);
    box-shadow: 0 4px 23px 0 rgb(0 0 0 / 20%);
    max-width: 550px;
    width: 50%;
  }
}
@media (max-width:1200px) and (min-width:992px){
  .pop_popup_body{
    position: fixed;
    left: 50%;
    top: 50%;
    transform: translate(-50%, -50%);
    box-shadow: 0 4px 23px 0 rgb(0 0 0 / 20%);
    max-width: 550px;
    width: 50%;
  }
}
@media (min-width:1200px){
  .pop_popup_body{
    position: fixed;
    left: 50%;
    top: 50%;
    transform: translate(-50%, -50%);
    box-shadow: 0 4px 23px 0 rgb(0 0 0 / 20%);
    max-width: 550px;
    width: 50%;
  }
}