.find_the_word_game_container{
  justify-content: center;
  align-items: center;
  display: flex;
  width: inherit;
  max-width: 500px;
  margin: 0 auto;
}
.game_container{
  display: block;
  width: inherit;
}
.page_container{
  display: flex;
}
.text_container{
  width: 100%;
  margin: auto 0;
}
.tamil_text{
  font-family: 'Kavivanar';
  font-weight: bold;
}
.key_board_main{
  width: 100%;
  margin: 0 auto;
}
.text_row{
  display: flex;
}
.text_box_container{
  margin: 5px;
  flex:1;
  overflow: hidden;
	-webkit-perspective: 1000;
	width: 100%;
}
.text_box{
  border: 1px #d4d4d4 solid;
  display: flex;
  justify-content: center;
  align-items: center;
  font-weight: 400;
  height: calc(100% - 2px);
}
.key_board_row{
  display: flex;
  flex-direction: row;
  margin: 3px auto;
  flex: 1;
}
.key_board_key{
  color: #3a3d42;
  text-shadow: 0 1px 1px white;
  background: #ecedef;
  margin: 4px;
  font: 13px 'Kavivanar';
  border-top: 1px solid rgba(255,255,255,.45);
  border-radius: 4px;
  cursor: pointer;
  margin-top: -2px;
  font-size: 1.3em;
  line-height: 20px;
  padding: 0 2px;

  justify-content: center;
  align-items: center;
  display: flex;
  flex: 1;
}
.key_notfound{
  background-color: gray;
  color: white;
}
.key_exist{
  background-color:#6fc772;
  color: white;
}
.key_board_key:hover{
  box-shadow: rgba(0,0,0,.35) 0 0 2px 1px;
  -o-box-shadow:rgba(0,0,0,.35) 0 0 2px 1px;
  -moz-box-shadow:rgba(0,0,0,.35) 0 0 2px 1px;
  -webkit-box-shadow:rgba(0,0,0,.35) 0 0 2px 1px;
  cursor:pointer;
}
.ng_action_timer{
  margin: 10px;
  align-items: center;
  color: #3c4043;
  cursor: pointer;
  display: inline-block;
  font-size: 14px;
  font-weight: 500;
  height: 48px;
  justify-content: center;
  text-align: center;
}
.show_result_text{
  background: black;
  color: white;
  padding: 10px;
  border-radius: 5px;
  text-align: center;
  margin: 0 auto;
}
.game_text_cell{
  margin: 5px;
  border: 1px #d4d4d4 solid;
  width: calc(100% - 10px);
  height: calc(100% - 10px);
  display: flex;
  justify-content: center;
  align-items: center;
  font-weight: 400;
  min-height: 35px;
}
.show_clue{
  border: 1px  solid lightgray;
  padding: 10px 20px;
  border-radius: 5px;
}
.show_clue:hover, .show_clue:active{
  background-color: lightgray;
  cursor: pointer;
}
.show_actual_clue{
  color: blue;
}

/* animatin stats here */
.tamilee_flipper {
 transition: 0.8s;
 -webkit-transform-origin: 100% 50%;
 -webkit-transform-style: preserve-3d;
 position: relative;
 height: 100%;
}
.tamilee_on {
 -webkit-transform: rotateX(-180deg);
}
.tamilee_off {
 -webkit-transform: rotateX(0deg);
}
.tamilee_front, .tamilee_back {
 width: 100%;
 height: 100%;
 position: absolute;
 -webkit-backface-visibility: hidden;
}
.text_box_container .tamilee_back {
 -webkit-transform: rotateX(180deg);
}
/* animatin ends here */

@media (max-width:576px){
  .key_board_main{ width: calc(100% - 16px); margin: 0 auto;}
  .key_board{ width: 100%; }
  .key_board_key{ height: 36px; }
  .tamil_text{ font-size: 0.9em; }
  .text_container{
    width: 85%;
    margin: auto;
  }
}
@media (max-width:768px) and (min-width:576px){
  .key_board{ width: 500px; margin: 0 auto; }
  .key_board_key{ height: 48px; }
  .tamil_text{ font-size: 20px; }
}
@media (max-width:992px) and (min-width:768px){
  .key_board{ width: 500px; margin: 0 auto; }
  .key_board_key{ height: 48px; }
  .tamil_text{ font-size: 24px; }
}
@media (max-width:1200px) and (min-width:992px){
  .key_board{ width: 500px; margin: 0 auto; }
  .key_board_key{ height: 48px; }
  .tamil_text{ font-size: 26px; }
}
@media (min-width:1200px){
  .key_board{ width: 500px; margin: 0 auto; }
  .key_board_key{ height: 48px; }
  .tamil_text{ font-size: 26px; }
}