.public_page{
    display: block;
    width: 100%;
    height: 100%;
    overflow-y: hidden;
}
.public_page_header{
    position: fixed;
    height: 60px;
}
.public_page_body{
    margin-top: 60px;
    width: 100%;
    height: calc(100% - 60px);
    overflow-y: auto;
}
.private_page{
    display: flex;
    margin-top: 60px;
    width: 100%;
    height: 100%;
}
.private_page_menu{
    width: 240px;
    background-color: #23263c;
}
.private_page_component{
    width: calc(100% - 240px);
    overflow-y: scroll;
}
.settings_grid{
    width: 100%;
    border-top: 1px solid #d3d6da;
}
.settings_item{
    display: flex;
    justify-content: space-between;
    align-items: center;
    border-bottom: 1px solid #d3d6da;
    padding: 16px 0;
}
.setting_item_name{
    padding-left: 8px;
    display: block;
    min-width: 50%;
    overflow-wrap: anywhere;
}
.setting_item_value{
    padding-right: 8px;
    display: block;
    width: 50%;
    max-width: 50%;
    overflow-wrap: anywhere;
    text-align: right;
}
.setting_item_value:hover{
    cursor: pointer;
    text-decoration: underline;
}
.version_info{
    padding: 16px;
    color: #777C7E;
    font-size: 12px;
    text-align: center;
    display: flex;
    justify-content: center;
    align-items: flex-end;
    height: 60px;
}