.header{
    font-size: 45px;
    padding-bottom: 8px;
    margin-bottom: 4px;
}
.header1{
    font-size: 32px;
    padding-bottom: 8px;
    margin-bottom: 4px;
}
.header3{
    font-size:20px;
    padding-bottom: 8px;
    margin-bottom: 4px; 
}
.p{
    font-size: 18px;
    font-weight: normal;
    padding-bottom: 8px;
    margin-bottom: 4px;
    text-align: justify;
}
.li{
    font-size: 18px;
    font-weight: normal;
    padding-bottom: 8px;
    margin-bottom: 4px;
}
.a{
    color: black;
    text-decoration: underline;
}

.pp_container{
    color: #1D3557;
    font-weight: bold;
    padding-bottom: 4px;
    padding-right:15px;
    padding-left:15px;
    margin:auto;
    text-align: left;
}
@media (min-width:576px){.pp_container{width:540px}}
@media (min-width:768px){.pp_container{width:720px}}
@media (min-width:992px){.pp_container{width:960px}}
@media (min-width:1200px){.pp_container{width:1140px}}