.txt_container{
    display: block;
    position: relative;
}
.txt_input,.txt_area{
    border: 2px solid #dfe1e6;
    border-radius: 3px;
    box-sizing: border-box;
    font-size: inherit;
    margin: 0;
    transition: background-color .2s ease-in-out,border-color .2s ease-in-out;
    vertical-align: initial;

    line-height: 1.42857143;
    padding: 3px 4px;
    width: 100%;
}
.txt_input{
    height: 33px;
}
.txt_area{
    height: 64px;
}
.txt_input:focus, .txt_area:focus{
    outline: none;
}
.txt_input:disabled, .txt_area:disabled{
    background-color: #f2f2f2;
}
.txt_information_text{
    font-weight: bold;
    margin: 0;
    padding: 3px;
}
.txt_white{
    color: white;
}
.txt_black{
    color: black;
}
.txt_error{
    border-color: crimson !important;
}
.txt_full{
    width: 100%;
}
.txt_min{
    width: 300px;
}
.txt_error_icon{
    position: absolute;
    bottom: 0;
    right: 0;
    display: flex;
    justify-content: flex-end;
}
.txt_error_icon:hover{
    cursor: pointer;
}